import React from "react";
import './imag.css'
import pic1 from '../ADHIBAWEBSITE-HOSTED/font/Adhiba-01 (1).png'
import pic2 from '../ADHIBAWEBSITE-HOSTED/font/Our Expertise-01 (1).png'
import pic3 from '../ADHIBAWEBSITE-HOSTED/font/Customisatio-01 (1).png'
import pic4 from '../ADHIBAWEBSITE-HOSTED/font/Our Products (1).png'
import pic5 from '../ADHIBAWEBSITE-HOSTED/font/Support-01 (1).png'
import pic6 from '../ADHIBAWEBSITE-HOSTED/font/Why Adhiba-01 (1).png'






export default function Tabs() {
  return (
    <>
      {/* new tab */}

      <section className="tab-adhiba">
        <h1 className="text-center ">LET YOU KNOW</h1>
        <div class="tab_container">
          <input id="tab1" type="radio" name="tabs" checked />
          <label for="tab1"><i class="fa fa-code"></i><span>Adhiba</span></label>

          <input id="tab2" type="radio" name="tabs" checked />
          <label for="tab2"><i class="fa fa-pencil-square-o"></i><span>Our Expertise</span></label>

          <input id="tab3" type="radio" name="tabs" checked />
          <label for="tab3"><i class="fa fa-bar-chart-o"></i><span>Customization</span></label>

          <input id="tab4" type="radio" name="tabs" checked />
          <label for="tab4"><i class="fa fa-folder-open-o"></i><span>Our Products</span></label>

          <input id="tab5" type="radio" name="tabs" checked />
          <label for="tab5"><i class="fa fa-envelope-o"></i><span>Support</span></label>

          <input id="tab6" type="radio" name="tabs" checked />
          <label for="tab6"><i class="fa fa-envelope-o"></i><span>Why Adhiba?</span></label>

          <section id="content1" class="tab-content" >
            <div class="card mb-3" >
              <div class="row g-0" style={{ display: "flex", alignItems: "center" }}>
                <div class="col-md-4 p-3">
                  <img src={pic1} class="img-fluid rounded-4" alt="..." />
                </div>
                <div class="col-md-8" >
                  <div class="card-body">
                    <div>
                      <h5 class="card-title">Adhiba</h5>
                      <p class="card-text">Empowering innovation in electronics, Adhiba Technologies brings you cutting-edge solutions for smart homes and advanced systems. With a decade of expertise, we combine technology and precision to deliver reliable products.<span> Explore our commitment to quality, cost-efficiency, and timely delivery,</span> designed to enhance modern living with seamless performance.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="content2" class="tab-content">
            <div class="card mb-3" >
              <div class="row g-0" style={{ display: "flex", alignItems: "center" }}>
                <div class="col-md-4 p-3">
                  <img src={pic2} class="img-fluid rounded-4" alt="..." />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <div>
                      <h5 class="card-title">Our Expertise</h5>
                      <p class="card-text">
                        At Adhiba, we specialize in electronic manufacturing, smart home innovation, and hardware-software integration. From ideation to execution, our solutions are crafted for industries, healthcare, and smart cities. <span>Leveraging advanced technology and deep industry knowledge,</span> we create tailored, future-ready solutions that empower businesses and individuals alike.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="content3" class="tab-content">
            <div class="card mb-3" >
              <div class="row g-0" style={{ display: "flex", alignItems: "center" }}>
                <div class="col-md-4 p-3">
                  <img src={pic3} class="img-fluid rounded-4" alt="..." />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <div>
                      <h5 class="card-title">Customization</h5>
                      <p class="card-text">
                        Discover solutions designed to meet diverse requirements—industries, VMS, hospitals, and smart cities. With energy-efficient systems, AI-powered insights, and scalable technology, <span>Adhiba ensures your unique challenges are addressed effectively. </span>Each solution is customized for maximum performance, security, and convenience, delivering unmatched value and reliability.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="content4" class="tab-content">
            <div class="card mb-3" >
              <div class="row g-0" style={{ display: "flex", alignItems: "center" }}>
                <div class="col-md-4 p-3">
                  <img src={pic4} class="img-fluid rounded-4" alt="..." />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <div>
                      <h5 class="card-title">Our Products</h5>
                      <p class="card-text">
                        Adhiba’s product range combines innovation and quality to redefine smart living and secure systems. From advanced hardware to integrated software, each product is designed for efficiency and durability. Our rigorous quality checks and <span>focus on user experience</span> ensure exceptional performance, making us a leader in the electronic manufacturing domain.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="content5" class="tab-content">
            <div class="card mb-3" >
              <div class="row g-0" style={{ display: "flex", alignItems: "center" }}>
                <div class="col-md-4 p-3">
                  <img src={pic5} class="img-fluid rounded-4" alt="..." />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <div>
                      <h5 class="card-title">Why Adhiba?</h5>
                      <p class="card-text">
                        Adhiba provides end-to-end support, from product development to post-installation assistance. Our team ensures seamless integration, timely updates, and unmatched <span>customer satisfaction.</span> Whether troubleshooting, upgrading, or customizing, our services are designed to simplify your experience, keeping your systems running smoothly and efficiently.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="content6" class="tab-content">
            <div class="card mb-3" >
              <div class="row g-0" style={{ display: "flex", alignItems: "center" }}>
                <div class="col-md-4 p-3">
                  <img src={pic6} class="img-fluid rounded-4" alt="..." />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <div>
                      <h5 class="card-title">Why Adhiba?</h5>
                      <p class="card-text">
                      <span>Adhiba provides end-to-end support, </span>from product development to post-installation assistance. Our team ensures seamless integration, timely updates, and unmatched customer satisfaction. Whether troubleshooting, upgrading, or customizing, our services are designed to simplify your experience, keeping your systems running smoothly and efficiently.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </section>


    </>
  )
}