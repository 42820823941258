import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import AOS from 'aos';
import 'aos/dist/aos.css';

import { HashRouter, Routes, Route } from 'react-router-dom';
import Layoutadhiba from './componuents/ADHIBAWEBSITE-HOSTED/layoutadhiba';
import Finaladhiba from './componuents/ADHIBAWEBSITE-HOSTED/intro';
import Supportadhiba from './componuents/ADHIBAWEBSITE-HOSTED/support';
import Aboutadhiba from './componuents/ADHIBAWEBSITE-HOSTED/ABOUTUS/Aboutus';
import Ourbusinessadhiba from './componuents/ADHIBAWEBSITE-HOSTED/OURBUSINESS/OURBUSINESS';
import Solutionadhiba from './componuents/ADHIBAWEBSITE-HOSTED/SOLUTIONPAGE/solution';
import Productadhiba from './componuents/ADHIBAWEBSITE-HOSTED/PRODUCTPAGE/product';


AOS.init();
function App() {
  return (
    <>

      <HashRouter>
        <Routes >
          <Route exact path="/*" element={<Layoutadhiba />}>
            <Route index element={<Finaladhiba />} />
            <Route exact path="adhiba-home" element={<Finaladhiba />} />
            <Route exact path="adhiba-about" element={<Aboutadhiba />} />
            <Route exact path="adhiba-Ourbusiness" element={<Ourbusinessadhiba />} />
            <Route exact path="adhiba-Products" element={<Productadhiba />} />
            <Route exact path="adhiba-Solutionadhiba" element={<Solutionadhiba />} />
            <Route exact path="adhiba-support" element={<Supportadhiba />} />
          </Route>
        </Routes>
      </HashRouter>

    </>
  );
}

export default App;
