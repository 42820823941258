import React, { useEffect } from "react";
import './productpage.css'
import img2 from "../PRODUCTPAGE/imgg2.png"
import img3 from "../PRODUCTPAGE/icon1.png"
import img4 from "../PRODUCTPAGE/imgg3.png"
import adhibabanner from "../PRODUCTPAGE/ADHIBA-PRODUCTS-BANNER.png"
import Footer from "../footer";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export default function Productadhiba() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {/* BANNER */}
            <section className="product-banner-adhiba">
                <img src={adhibabanner} className="img-fluid w-100 rounded-0" />
            </section>

            {/* PRODUCTS SECTION */}
            <section className="product-des-section-adhiba container">

                {/* PRODUCT 1 HYBRID AIoT NVR*/}

                <div className="products-main-contents-adhiba">
                    <div className="row">

                        <div className="col-lg-6 products-left-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <img src={img2} className="img-fluid pb-4" />
                        </div>



                        <div className="col-lg-6 products-right-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <h3>HYBRID AI IoT NVR</h3>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Integration of AI and IoT Technologies:
                                </span>
                                NYSTAI's Hybrid AIoT NVR combines advanced AI and IoT technologies, providing seamless video surveillance solutions that enhance security systems.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Intelligent Video Analytics and Real-Time Monitoring:
                                </span>
                                The system offers smart detection, real-time monitoring, and intelligent video analytics to improve surveillance efficiency and accuracy.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Performance, Reliability, and Scalability:
                                </span>
                                Designed for modern businesses, the Hybrid AIoT NVR ensures high performance, reliability, and scalability to meet all security needs.
                            </p>

                            

                        </div>

                    </div>
                </div>

                {/* PRODUCT 2 INDUSTRIAL AI-NVR*/}

                <div className="products-main-contents-adhiba">
                    <div className="row d-flex align-items-center flex-column-reverse flex-lg-row">

                        <div className="col-lg-6 products-right-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <h3>INDUSTRIAL AI-NVR</h3>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    AI-Driven Analytics and Industrial Performance:
                                </span>
                                NYSTAI's Industrial AI-NVR integrates advanced AI analytics with industrial-grade performance, ensuring superior video surveillance in demanding environments.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Real-Time Insights and Automated Detection:
                                </span>
                                The system delivers real-time insights and automated detection, enhancing security and operational efficiency.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Scalable and Reliable Solutions for Industrial Sectors:
                                </span>
                                Tailored for large-scale operations, the Industrial AI-NVR provides scalable and reliable solutions to meet the specific needs of industrial sectors.
                            </p>

                            

                        </div>

                        <div className="col-lg-6 products-left-contents-adhiba  pb-4"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <img src={img2} className="img-fluid pb-4" />
                        </div>

                    </div>
                </div>

                {/* PRODUCT 3 ALARM & AUTOMATION*/}

                <div className="products-main-contents-adhiba">
                    <div className="row">

                        <div className="col-lg-6 products-left-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <img src={img2} className="img-fluid pb-4" />
                        </div>


                        <div className="col-lg-6 products-right-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <h3>ALARM & AUTOMATION</h3>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Integration of Smart Technologies:
                                </span>
                                NYSTAI's Alarm and Automation solutions use smart technologies to enhance security and improve operational efficiency.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Real-Time Alerts and Automated Responses:
                                </span>
                                The systems offer real-time alerts and automated responses, enabling faster reactions to threats and optimized processes.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Seamless Integration and Reliability:
                                </span>
                                With seamless integration into existing infrastructure, NYSTAI’s solutions enhance safety and streamline operations through intelligent automation and reliable alarm systems.
                            </p>

                            

                        </div>

                    </div>
                </div>

                {/* PRODUCT 4 CCTV CAMERA*/}

                <div className="products-main-contents-adhiba">
                    <div className="row d-flex align-items-center flex-column-reverse flex-lg-row">

                        <div className="col-lg-6 products-right-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <h3>AI SURVEILLANCE CAMERA</h3>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    High-Definition Surveillance with Advanced Features:
                                </span>
                                NYSTAI's CCTV cameras provide high-definition video surveillance, featuring motion detection, night vision, and remote monitoring for comprehensive coverage
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Round-the-Clock Security for Residential and Commercial Use:
                                </span>
                                Designed for both residential and commercial environments, our cameras ensure continuous, reliable security and detailed monitoring.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Durability and Seamless Integration:
                                </span>
                                Offering advanced durability and seamless integration, NYSTAI’s CCTV solutions provide unmatched reliability for enhanced safety and peace of mind.
                            </p>

                            

                        </div>

                        <div className="col-lg-6 products-left-contents-adhiba  pb-4"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <img src={img2} className="img-fluid pb-4" />
                        </div>

                    </div>
                </div>

                {/* PRODUCT 5 SENSORS*/}

                <div className="products-main-contents-adhiba">
                    <div className="row">

                        <div className="col-lg-6 products-left-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <img src={img2} className="img-fluid pb-4" />
                        </div>


                        <div className="col-lg-6 products-right-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <h3>SENSORS</h3>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Precision and Reliability in Data Collection:
                                </span>
                                NYSTAI's sensors are designed for accuracy and reliability, enabling real-time data collection and monitoring across various applications.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Enhanced Security and Efficiency:
                                </span>
                                Whether for motion detection, temperature, humidity, or air quality, our sensors improve security, efficiency, and automation in smart environments.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    seamless Integration and Actionable Insights:
                                </span>
                                SEngineered for easy integration, NYSTAI’s sensors provide actionable insights that ensure optimal performance and proactive decision-making.
                            </p>

                            

                        </div>

                    </div>
                </div>

                {/* PRODUCT 6 SMART SWITCH*/}

                <div className="products-main-contents-adhiba">
                    <div className="row d-flex align-items-center flex-column-reverse flex-lg-row">

                        <div className="col-lg-6 products-right-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <h3>SMART SWITCH</h3>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Intuitive Control and Remote Operation:
                                </span>
                                NYSTAI's smart switches allow easy control over lighting and electrical systems, with remote operation via smartphone or voice commands.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Seamless Integration and Energy Efficiency:
                                </span>
                                Designed for both smart homes and businesses, our switches integrate effortlessly into existing setups, promoting convenience and energy efficiency.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Advanced Features for Comfort and Security:
                                </span>
                                With features like scheduling, automation, and real-time monitoring, our smart switches enhance comfort, security, and power management.
                            </p>

                            

                        </div>

                        <div className="col-lg-6 products-left-contents-adhiba  pb-4"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <img src={img2} className="img-fluid pb-4" />
                        </div>

                    </div>
                </div>

                {/* PRODUCT 7 ACCESS CONTROL*/}

                <div className="products-main-contents-adhiba">
                    <div className="row">

                        <div className="col-lg-6 products-left-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <img src={img2} className="img-fluid pb-4" />
                        </div>


                        <div className="col-lg-6 products-right-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <h3>ACCESS CONTROL</h3>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Secure and Scalable Access Management:
                                </span>
                                NYSTAI's access control solutions offer secure, scalable, and efficient management of entry points across residential, commercial, and industrial spaces.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    dvanced Technologies for Seamless Security:
                                </span>
                                AFeaturing biometric recognition, RFID, and mobile credentials, our systems provide seamless, contactless security for authorized access.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    lexible and Reliable Solutions for Enhanced Safety:
                                </span>
                                FDesigned for flexibility and reliability, NYSTAI’s access control systems ensure only authorized access, boosting safety and streamlining operations.
                            </p>

                            

                        </div>

                    </div>
                </div>

                {/* PRODUCT 8 DIGITAL CLASS*/}

                <div className="products-main-contents-adhiba">
                    <div className="row d-flex align-items-center flex-column-reverse flex-lg-row">

                        <div className="col-lg-6 products-right-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <h3>DIGITAL CLASS</h3>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Transforming Traditional Learning Environments:
                                </span>
                                NYSTAI's Digital Class solutions convert traditional classrooms into interactive, tech-driven spaces with smart devices, collaborative tools, and real-time analytics.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Personalized and Engaging Educational Experiences:
                                </span>
                                Our solutions enable personalized learning, fostering engaging educational experiences for both students and educators..
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Enhanced Flexibility, Accessibility, and Connectivity:
                                </span>
                                Designed for both in-person and remote learning, the Digital Class empowers educators and students with greater flexibility, accessibility, and connectivity.
                            </p>

                            

                        </div>

                        <div className="col-lg-6 products-left-contents-adhiba  pb-4"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <img src={img2} className="img-fluid pb-4" />
                        </div>

                    </div>
                </div>

                {/* PRODUCT 9 AI-VMS*/}

                <div className="products-main-contents-adhiba">
                    <div className="row">

                        <div className="col-lg-6 products-left-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <img src={img2} className="img-fluid pb-4" />
                        </div>


                        <div className="col-lg-6 products-right-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <h3>AI-VMS</h3>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Intelligent, Automated Monitoring:
                                </span>
                                NYSTAI's AI-VMS integrates advanced AI analytics with video surveillance, offering intelligent features like object detection, facial recognition, and real-time alerts.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Enhanced Security and Operational Efficiency:
                                </span>
                                The system improves security by enabling automated monitoring and enhances operational efficiency through smart features and real-time insights.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Seamless Scalability and Integration:
                                </span>
                                Designed for any environment, our AI-VMS offers seamless scalability and integration, providing smarter and more effective video surveillance solutions.
                            </p>

                            

                        </div>

                    </div>
                </div>

                {/* PRODUCT 10 WATER LEAKAGE*/}

                <div className="products-main-contents-adhiba">
                    <div className="row d-flex align-items-center flex-column-reverse flex-lg-row">

                        <div className="col-lg-6 products-right-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <h3>WATER LEAKAGE</h3>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Early Detection and Real-Time Alerts:
                                </span>
                                NYSTAI’s water leakage detection solutions provide early identification of leaks and real-time alerts to prevent costly water damage.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Advanced Sensors and AI-Powered Analytics:
                                </span>
                                Utilizing advanced sensors and AI-driven analytics, our system quickly detects leaks, allowing businesses and homeowners to take immediate action.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Seamless Integration and Automated Responses:
                                </span>
                                With seamless integration and automated responses, our solution ensures reliable protection against water-related risks, safeguarding assets and infrastructure.
                            </p>

                            

                        </div>

                        <div className="col-lg-6 products-left-contents-adhiba  pb-4"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <img src={img2} className="img-fluid pb-4" />
                        </div>

                    </div>
                </div>

                {/* PRODUCT 11 ELECTRIC FENCE*/}

                <div className="products-main-contents-adhiba">
                    <div className="row">

                        <div className="col-lg-6 products-left-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <img src={img2} className="img-fluid pb-4" />
                        </div>


                        <div className="col-lg-6 products-right-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <h3>AI-VMS</h3>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Enhanced Security with High-Voltage Barrier:
                                </span>
                                NYSTAI’s electric fence solutions create a high-voltage barrier that effectively deters unauthorized access and intruders, enhancing security.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Durability, Real-Time Monitoring, and Customization:
                                </span>
                                Designed for reliability, our systems include real-time monitoring, alarm integration, and customizable settings to meet various security needs.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Versatile Protection for All Applications:
                                </span>
                                Ideal for residential, commercial, and industrial use, our electric fences provide a powerful and effective layer of protection
                            </p>

                            

                        </div>

                    </div>
                </div>

                {/* PRODUCT 12 FIRE SAFETY*/}

                <div className="products-main-contents-adhiba">
                    <div className="row d-flex align-items-center flex-column-reverse flex-lg-row">

                        <div className="col-lg-6 products-right-contents-adhiba"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <h3>FIRE SAFETY</h3>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Advanced Detection and Monitoring Systems:
                                </span>
                                NYSTAI's fire safety solutions include smoke detectors, fire alarms, and automatic suppression systems, designed to protect lives and property with proactive detection and monitoring.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Real-Time Alerts and Rapid Response:
                                </span>
                                Our integrated approach ensures real-time alerts and rapid response, enhancing fire safety and minimizing risks.
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCircle} className="icon-product-adhiba" />
                                <span className="me-2">
                                    Comprehensive Protection Across Environments:
                                </span>
                                Tailored for residential, commercial, and industrial settings, our solutions provide enhanced security and proactive fire safety across various environments.
                            </p>

                            

                        </div>

                        <div className="col-lg-6 products-left-contents-adhiba  pb-4"
                            data-aos="zoom-in"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false"
                            data-aos-anchor-placement="top">
                            <img src={img2} className="img-fluid pb-4" />
                        </div>

                    </div>
                </div>

            </section>

            {/* FOOTER */}
            <Footer />

        </>
    )
}