import React, { useEffect } from "react";
import './ourbuss.css'
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import testiimg1 from "../IMAGES-ADHIBA/ABOUTPAGE/image (59).png"
import { faAngleRight, faCircleUser } from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';

// IMAGES
import ourbusinessimg1 from "../IMAGES-ADHIBA/OURBUSINESSPAGE/OURBUSINESS1.png"
import ourbusinessimg2 from "../IMAGES-ADHIBA/OURBUSINESSPAGE/OURBUSINESS2.png"
import ourbusinessimg3 from "../IMAGES-ADHIBA/OURBUSINESSPAGE/OURBUSINESS3.png"
import ourbusinessimg4 from "../IMAGES-ADHIBA/OURBUSINESSPAGE/ARROW-1.svg"
import Footer from "../footer";

export default function Ourbusinessadhiba() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {/* BANNER SECTION */}
            <section className="banner-section-ourbusiness-adhiba">
                <div className="container">
                    <div className="row">
                        <dic className="col-lg-6 banner-left-contents-adhiba">
                            <div className="">
                                <h3>Our business</h3>
                                <p>We specialize in providing innovative, high-quality electronic solutions, including smart home systems, security products, and IT services, designed to enhance efficiency, safety, and convenience across industries.</p>
                                <button className="mt-3">Learn More</button>
                            </div>
                        </dic>
                        <div className="col-lg-6">
                            <img src={ourbusinessimg1} className="img-fluid w-100" style={{ borderRadius: "0" }} />
                        </div>
                    </div>
                </div>
            </section>

            {/* OUR MISSION */}
            <section className="four-cards-section-adhiba">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src={ourbusinessimg2} className="img-fluid w-100" style={{ borderRadius: "0" }} />
                        </div>
                        <div className="col-lg-6 four-cards-main-card-div-adhiba">
                            <div className="row">
                                <div class="card col-lg-6 mb-4">
                                    <div class="card-body">
                                        <h5 class="card-title">Our Culture</h5>
                                        <p class="card-text">Innovation-driven, customer-focused, and committed to excellence in every aspect of design and service.</p>
                                    </div>
                                </div>
                                <div class="card col-lg-6 mb-4">
                                    <div class="card-body">
                                        <h5 class="card-title">Our Mission</h5>
                                        <p class="card-text">To lead globally in smart electronics, enhancing modern living with innovative, high-quality solutions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                <div class="card col-lg-6 mb-4">
                                    <div class="card-body">
                                        <h5 class="card-title">What we do</h5>
                                        <p class="card-text"> We deliver advanced smart home solutions and precision-engineered hardware/software for diverse industries.</p>
                                    </div>
                                </div>
                                <div class="card col-lg-6 mb-4">
                                    <div class="card-body">
                                        <h5 class="card-title">How we do</h5>
                                        <p class="card-text">By blending cutting-edge technology, quality manufacturing, and a customer-first approach to exceed expectations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* WE BELIEVE IN */}
            <section className="we-believein-section-adhiba container">
                <div className="we-believein-heading-adhiba">
                    <h3>We Believe In</h3>
                </div>
                <div className="we-believein-4box-contents-adhiba">
                    <div className="row ">
                        <div className="col-lg-6 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">01</h5>
                                    <h6 class="card-subtitle">Innovation</h6>
                                    <p class="card-text">We embrace cutting-edge technology to create forward-thinking solutions that push boundaries and enhance user experiences.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">02</h5>
                                    <h6 class="card-subtitle">Quality</h6>
                                    <p class="card-text">We are committed to delivering high-quality products that meet the highest standards of performance and reliability.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">03</h5>
                                    <h6 class="card-subtitle">Precision</h6>
                                    <p class="card-text">We focus on meticulous attention to detail in our manufacturing processes to ensure flawless execution and consistency.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">04</h5>
                                    <h6 class="card-subtitle">Satisfaction</h6>
                                    <p class="card-text"> We prioritize customer satisfaction by providing exceptional service and delivering solutions that exceed expectations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* WE PARTNER */}
            <section className="We-partner-main-section-adhiba">
                <div className="container">

                    <div class="row d-flex align-items-center flex-column-reverse flex-lg-row">
                        <div className="col-lg-3 We-partner-left-contents-adhiba">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">We partner with organizations that are ready to innovate, disrupt their industries, and push the boundaries of what’s possible.</h5>
                                    <p class="card-text">We collaborate with forward-thinking companies that share our passion for transformation, driving progress and redefining industry standards together.</p>
                                    <Link to="/adhiba-support"><button>Contact Us</button></Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-9" >
                            <img src={ourbusinessimg3} className="img-fluid w-100" style={{ borderRadius: "0" }} />
                        </div>

                    </div>
                </div>
            </section>

            {/* WE OFFER */}
            <section className="we-offer-innovative-section-adhiba">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="we-offer-innovative-heading-adhiba sticky-top " style={{ top: "8rem" }}>
                                <h3>We Offer Innovative Products and Expert Services </h3>
                                <p>We offer tailored solutions, expert support, and seamless integration to maximize product value.</p>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-6 we-offer-innovative-right-contents-adhiba mb-3">
                                    <div class="card" >
                                        <div class="card-body">
                                            <h5 class="card-title">Security Products </h5>
                                            <p class="card-text">We offer a wide range of CCTV security products, including cameras, recorders, and monitoring systems, designed to provide reliable, high-quality surveillance for enhanced security.</p>
                                            <div className="we-offer-hover-4cards-contents-adhiba">
                                                <a href="https://nystai.com/" target="_blank" className="d-flex justify-content-between " style={{ textDecoration: "none", color: "white" }}>
                                                    <p>www.nystai.com</p>
                                                    <img src={ourbusinessimg4} className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 we-offer-innovative-right-contents-adhiba mb-3">
                                    <div class="card" >
                                        <div class="card-body">
                                            <h5 class="card-title">CCTV Rental</h5>
                                            <p class="card-text">We offer flexible CCTV rental services, providing high-quality surveillance equipment with installation, maintenance, and support for enhanced security.</p>
                                            <div className="we-offer-hover-4cards-contents-adhiba">
                                                <a href="https://rental.nystai.in/" target="_blank" className="d-flex justify-content-between " style={{ textDecoration: "none", color: "white" }}>
                                                    <p>www.nystairental.com</p>
                                                    <img src={ourbusinessimg4} className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-lg-6 we-offer-innovative-right-contents-adhiba mb-3">
                                    <div class="card" >
                                        <div class="card-body">
                                            <h5 class="card-title">CCTV Manufacturing</h5>
                                            <p class="card-text">We specialize in electronics manufacturing, offering precision-engineered products with advanced technology, ensuring high quality and reliable performance.</p>
                                            <div className="we-offer-hover-4cards-contents-adhiba">
                                                <a href="https://adhiba.com/" target="_blank" className="d-flex justify-content-between " style={{ textDecoration: "none", color: "white" }}>
                                                    <p>www.adhiba.com</p>
                                                    <img src={ourbusinessimg4} className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 we-offer-innovative-right-contents-adhiba mb-3">
                                    <div class="card" >
                                        <div class="card-body">
                                            <h5 class="card-title">IT Solutions</h5>
                                            <p class="card-text">We provide comprehensive IT solutions, including software development, system integration, and IT consulting, to drive business efficiency and innovation.</p>
                                            <div className="we-offer-hover-4cards-contents-adhiba">
                                                <a href="https://it.adhiba.com/" target="_blank" className="d-flex justify-content-between " style={{ textDecoration: "none", color: "white" }}>
                                                    <p>www.it.adhiba.com</p>
                                                    <img src={ourbusinessimg4} className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </section>

            {/* TESTIMONIAL */}
            <section className="Testimonial-about-adhiba">
                <div className="half-color-box">
                    <div className="container spacer por">
                        <div className="Testimonial-heading-div">
                            <h3 className="pt-5">Testimonials</h3>
                            <p>
                                Our testimonials reflect our commitment to excellence, showcasing how our <br />innovative solutions and exceptional service have exceeded customer expectations.
                            </p>
                        </div>
                        <div className="test-arrowbox">
                            <div className="swiper-button-prev-test">
                                <svg fill="#393185" height="34" className="arrow left">
                                    <use xlinkHref="#arrow" />
                                </svg>
                            </div>
                            <div className="swiper-button-next-test">
                                <div className="arrow-right">
                                    <svg fill="#393185" height="60" width="60" className="arrow">
                                        <use xlinkHref="#arrow" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <Swiper
                            className="swiper-container swiper-testimonial"
                            slidesPerView={3}
                            spaceBetween={30}
                            navigation={{
                                nextEl: '.swiper-button-next-test',
                                prevEl: '.swiper-button-prev-test',
                            }}

                        >
                            {/* Slide 1 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=1"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Amanda Jackson</h5>
                                            <p className="user-post">CEO, NRD Group</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum at arcu a arcu
                                        tincidunt condimentum.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 2 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=2"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>John Doe</h5>
                                            <p className="user-post">Designer, ABC Studio</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum at arcu a arcu
                                        tincidunt condimentum.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 3 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=3"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Jane Smith</h5>
                                            <p className="user-post">Manager, XYZ Corp</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 4 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=3"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Jane Smith</h5>
                                            <p className="user-post">Manager, XYZ Corp</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 5 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=3"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Jane Smith</h5>
                                            <p className="user-post">Manager, XYZ Corp</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 6 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=3"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Jane Smith</h5>
                                            <p className="user-post">Manager, XYZ Corp</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>
                        </Swiper>

                        {/* <div className="swiper-pagination"></div> */}
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
                        <symbol id="arrow" viewBox="0 0 400.004 400.004">
                            <g>
                                <path d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z" />
                            </g>
                        </symbol>
                    </svg>
                </div>
                <img src={testiimg1} className="img-fluid w-100" style={{ borderRadius: "0" }} />
            </section>

            {/* FOOTER */}
            <Footer />

        </>
    )
}