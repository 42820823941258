import React, { useEffect } from "react";
import './solu.css'
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import testiimg1 from "../IMAGES-ADHIBA/ABOUTPAGE/image (59).png"
import { faAngleRight, faCircleUser } from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';

import Footer from "../footer";

// IMAGE
import holisticimg1 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY1.png"
import holisticimg2 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY2.png"
import holisticimg3 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY3.svg"
import holisticimg4 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY4.png"
import holisticimg5 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY5.png"
import holisticimg6 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY6.png"
import holisticimg7 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY7.png"
import holisticimg8 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY8.png"
import holisticimg9 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY9.png"
import holisticimg10 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY10.png"
import holisticimg11 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY11.png"
import holisticimg12 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY12.png"
import holisticimg13 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY13.png"
import holisticimg14 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY14.png"
import holisticimg15 from "../IMAGES-ADHIBA/HOLISTICSECURITY/HOLISTICSECURITY15.png"

export default function Solutionadhiba() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* HOLISTIC SECURITY SOLUTION */}
            <section className="holistic-security-section-adhiba">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6" style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                            <img src={holisticimg1} className="img-fluid" style={{ borderRadius: "0" }} />
                        </div>
                        <div className="col-lg-6 holistic-security-right-contents-adhiba" style={{ display: "flex", justifyContent: "center", alignItems: "end" }}>
                            <div>
                                <h3>Holistic Security Solutions</h3>
                                <img src={holisticimg2} className="img-fluid" style={{ borderRadius: "0" }} />
                                <p>Our Holistic Security Solutions cover every aspect of security, offering integrated systems and services to protect people, assets, and data.</p>
                                <Link to="https://nystai.com/" target="_blank">
                                    <div className="d-flex">
                                        <h6 className="pt-3">Learn More </h6>&nbsp;&nbsp;
                                        <img src={holisticimg3} className="" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* QUICK, SMART AND GREAT SOLUTION */}
            <section className="quick-smart-solution-section-adhiba">
                <div className="container">
                    <div className="quick-smart-solution-heading-adhiba">
                        <h3>QUICK, SMART AND GREAT <br /> SOLUTION</h3>
                    </div>

                    <div className="quick-smart-solution-contents-adhiba">
                        <div className="row">
                            <div className="col-lg-4 quick-smart-solution-left-contents-adhiba" style={{ display: "flex", justifyContent: "center"}}>
                                <div>
                                    <img src={holisticimg4} className="img-fluid" style={{ borderRadius: "0", height: "100px", width: "100px", marginBottom:"30px" }} />
                                </div>
                            </div>

                            <div className="col-lg-8 quick-smart-solution-right-contents-adhiba">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Security solution</h5>
                                        <p class="card-text">We offer robust Security Solutions that include state-of-the-art CCTV systems, surveillance equipment, and integrated services, ensuring comprehensive protection and peace of mind for businesses and residential spaces.</p>
                                        <a href="https://nystai.com/#/nystai-solution-home" target="_blank" class="card-link">View</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                        <div className="col-lg-4 quick-smart-solution-left-contents-adhiba" style={{ display: "flex", justifyContent: "center"}}>
                                <div>
                                    <img src={holisticimg4} className="img-fluid" style={{ borderRadius: "0", height: "100px", width: "100px", marginBottom:"30px" }} />
                                </div>
                            </div>

                            <div className="col-lg-8 quick-smart-solution-right-contents-adhiba">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">IT solution</h5>
                                        <p class="card-text">Our IT Solutions encompass software development, system integration, and expert IT consulting, designed to enhance business efficiency, streamline operations, and drive innovation through cutting-edge technology.</p>
                                        <a href="https://it.adhiba.com/" target="_blank" class="card-link">View</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            {/* WE PROVIDE PERFECT SOLUTION */}
            <section className="We-provide-perfect-solution-section-adhiba">
                <div className="container">
                    <div className="We-provide-perfect-solution-heading-adhiba">
                        <h3>We provide the perfect solution</h3>
                        <p>We provide the perfect solution by combining advanced technology, expert knowledge, and customized approaches tailored<br /> to each client’s specific needs. Our focus on quality, innovation, and reliability ensures that every solution delivers maximum <br /> value and performance.</p>
                    </div>

                    <div className="row we-provide-solution-icons-text-adhiba">
                        <div className="col-lg-3 col-sm-6 col-6">
                            <Link to="https://nystai.com/#/nystai-solution-home" target="_blank">
                            <img src={holisticimg5} className="img-fluid " style={{ borderRadius: "0" }} />
                            <p>SMARTHOME</p>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-6">
                            <Link to="https://nystai.com/#/nystai-solution-Industrial" target="_blank">
                            <img src={holisticimg6} className="img-fluid " style={{ borderRadius: "0" }} />
                            <p>INDUSTRY</p>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-6">
                            <Link to="https://nystai.com/#/nystai-solution-education" target="_blank">
                            <img src={holisticimg7} className="img-fluid " style={{ borderRadius: "0" }} />
                            <p>EDUCATION</p>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-6">
                            <Link to="" target="_blank">
                            <img src={holisticimg8} className="img-fluid " style={{ borderRadius: "0" }} />
                            <p>WORSHIP</p>
                            </Link>
                        </div>

                        <div className="row we-provide-solution-icons-text-adhiba">
                            <div className="col-lg-3 col-sm-6 col-6">
                                <Link to="https://nystai.com/#/nystai-solution-vms" target="_blank">
                                <img src={holisticimg9} className="img-fluid " style={{ borderRadius: "0" }} />
                                <p>VMS</p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-6">
                                <Link to="https://nystai.com/#/nystai-solution-Warehouse" target="_blank">
                                <img src={holisticimg10} className="img-fluid " style={{ borderRadius: "0" }} />
                                <p>WAREHOUSE</p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-6">
                                <Link to="https://nystai.com/#/nystai-solution-Hospital" target="_blank">
                                <img src={holisticimg11} className="img-fluid " style={{ borderRadius: "0" }} />
                                <p>HOSPITAL</p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-6">
                                <Link to="" target="_blank">
                                <img src={holisticimg12} className="img-fluid " style={{ borderRadius: "0" }} />
                                <p>BANKING</p>
                                </Link>
                            </div>
                        </div>
                        <div className="row we-provide-solution-icons-text-adhiba">
                            <div className="col-lg-3 col-sm-6 col-6">
                                <Link to="" target="_blank">
                                <img src={holisticimg13} className="img-fluid " style={{ borderRadius: "0" }} />
                                <p>RETAIL</p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-6">
                                <Link to="https://nystai.com/#/nystai-solution-parking" target="_blank">
                                <img src={holisticimg14} className="img-fluid " style={{ borderRadius: "0" }} />
                                <p>PARKING LOT</p>
                                </Link>
                            </div>

                        </div>
                    </div>

                </div>
            </section>

            {/* OUR SOLUTION */}
            <section className="Our-solutions-section-adhiba">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-7 Our-solutions-left-contents-adhiba">
                            <div>
                                <h3>99%</h3>
                                <p>Our solutions are built on a foundation of quality, precision, and customer-centricity, ensuring that each product and service is perfectly aligned with client requirements. With a commitment to continuous improvement and innovation, we empower organizations to stay ahead of the curve, providing lasting value and unmatched reliability across all sectors.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 Our-solutions-right-contents-adhiba">
                            <img src={holisticimg15} className="img-fluid " style={{ borderRadius: "0" }} />
                        </div>
                    </div>
                </div>
            </section>

            {/* READY TO GO */}
            <section className="ready-to-go-section-adhiba">
                <div className="container">
                    <div className="ready-to-go-heading-adhiba">
                        <h3>READY TO GO ? WE GOT YOU</h3>
                        <p>We are ready to deliver our trusted solutions, offering reliable, high-quality products and services to meet your needs. With <br /> our expertise and commitment to excellence, we ensure that every customer receives the best-in-class solutions for ultimate <br /> satisfaction and security.</p>
                    </div>
                    <div className="ready-to-go-button-adhiba">
                        <Link to="/adhiba-support">   <button className="mt-3">Contact Us</button></Link>
                    </div>
                </div>
            </section>

            {/* TESTIMONIAL */}
            <section className="Testimonial-about-adhiba">
                <div className="half-color-box">
                    <div className="container spacer por">
                        <div className="Testimonial-heading-div">
                            <h3 className="pt-5">Testimonials</h3>
                            <p>
                                Our testimonials reflect our commitment to excellence, showcasing how our <br />innovative solutions and exceptional service have exceeded customer expectations.
                            </p>
                        </div>
                        <div className="test-arrowbox">
                            <div className="swiper-button-prev-test">
                                <svg fill="#393185" height="34" className="arrow left">
                                    <use xlinkHref="#arrow" />
                                </svg>
                            </div>
                            <div className="swiper-button-next-test">
                                <div className="arrow-right">
                                    <svg fill="#393185" height="60" width="60" className="arrow">
                                        <use xlinkHref="#arrow" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <Swiper
                            className="swiper-container swiper-testimonial"
                            slidesPerView={3}
                            spaceBetween={30}
                            navigation={{
                                nextEl: '.swiper-button-next-test',
                                prevEl: '.swiper-button-prev-test',
                            }}

                        >
                            {/* Slide 1 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=1"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Amanda Jackson</h5>
                                            <p className="user-post">CEO, NRD Group</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum at arcu a arcu
                                        tincidunt condimentum.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 2 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=2"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>John Doe</h5>
                                            <p className="user-post">Designer, ABC Studio</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum at arcu a arcu
                                        tincidunt condimentum.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 3 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=3"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Jane Smith</h5>
                                            <p className="user-post">Manager, XYZ Corp</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 4 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=3"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Jane Smith</h5>
                                            <p className="user-post">Manager, XYZ Corp</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 5 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=3"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Jane Smith</h5>
                                            <p className="user-post">Manager, XYZ Corp</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 6 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=3"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Jane Smith</h5>
                                            <p className="user-post">Manager, XYZ Corp</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>
                        </Swiper>

                        {/* <div className="swiper-pagination"></div> */}
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
                        <symbol id="arrow" viewBox="0 0 400.004 400.004">
                            <g>
                                <path d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z" />
                            </g>
                        </symbol>
                    </svg>
                </div>
                <img src={testiimg1} className="img-fluid w-100" style={{ borderRadius: "0" }} />
            </section>

            <Footer />

        </>
    );
}

