import React, { useState, useEffect } from "react";
import './About.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Images
import LandingAboutimg from "../IMAGES-ADHIBA/ABOUTPAGE/ABOUT2.png"
import Aboutimg1 from "../IMAGES-ADHIBA/ABOUTPAGE/ABOUT1.png"
import twoimg1 from "../IMAGES-ADHIBA/ABOUTPAGE/Group 28.png"
import ourvisionimg1 from "../IMAGES-ADHIBA/OURBUSINESSPAGE/OURVISIONIMG1.png"

import testiimg1 from "../IMAGES-ADHIBA/ABOUTPAGE/image (59).png"
import { faAngleRight, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import Footer from "../footer";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Link } from "react-router-dom";

export default function Aboutadhiba() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            {/* LANDING BANNER ABOUT US */}
            <section className="Landing-banner-aboutus" >
                <div>
                    <img src={LandingAboutimg} className="img-fluid w-100" style={{ borderRadius: "0%" }} />
                </div>
            </section>

            {/* ABOUT US DESIGN LEFT AND RIGHT CARD DESIGN */}
            <section className="left-right-card-adhiba ">
                <div className="container">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="">
                                    <img src={Aboutimg1} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-6 right-contents-adhiba">
                                <h3>About us</h3>
                                <p>Headquartered in Coimbatore, Adhiba Technologies is a leading innovator in electronic manufacturing, specializing in smart home solutions, as well as hardware and software development. With over a decade of industry expertise, we are driven by our core principles of Best Quality, Right Cost, and On-Time Delivery. Our cutting-edge products blend advanced technology with precision manufacturing to offer unparalleled convenience, performance, and reliability. Committed to redefining modern living, Adhiba Technologies delivers exceptional value through superior products and outstanding service, ensuring customer satisfaction at every touchpoint. We continuously strive to exceed expectations and empower the future of smart living.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ACCORDIAN AND LEFT CONTENT DESIGN */}
            <section className="Accordion-About-Adhiba container">
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-6 left-about-contents-adhiba">
                            <div>
                                <h3>Why choose us?</h3>
                                <p>Adhiba Technologies combines cutting-edge innovation, precision manufacturing, and unparalleled service to deliver high-quality, cost-effective electronic solutions, empowering modern living with smart, reliable, and customer-focused products.</p>
                                <Link to="/adhiba-Ourbusiness"><button className="mt-3">Learn More</button></Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="accordion" id="accordionPanelsStayOpenExample">
                                {/* ACCORDION 1 */}
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                            Cutting-Edge AIoT and Machine <br />Perception Technology
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                        <div class="accordion-body">
                                        Our cutting-edge AIoT (Artificial Intelligence of Things) and Machine Perception Technology seamlessly integrate AI with IoT devices, enabling smart, automated decision-making. This technology allows systems to analyze and respond to real-time data, enhancing efficiency and performance across various industries. By combining intelligent algorithms with advanced sensors, we empower businesses to unlock new possibilities and optimize operations.
                                        </div>
                                    </div>
                                </div>
                                {/* ACCORDION 2 */}
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                            Comprehensive, Industry-Specific Solutions <br />for Global Markets
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                        <div class="accordion-body">
                                        We offer comprehensive, industry-specific solutions tailored to meet the unique needs of global markets. Our products and services are designed to address the challenges and opportunities within each sector, ensuring relevance and impact across diverse regions. By leveraging deep industry expertise and cutting-edge technology, we deliver customized solutions that drive growth, efficiency, and innovation worldwide.
                                        </div>
                                    </div>
                                </div>
                                {/* ACCORDION 3 */}
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                            Global Presence with Localized <br />Support
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                        <div class="accordion-body">
                                        With a global presence, we ensure that our customers receive personalized, localized support wherever they are. Our teams are strategically located to offer timely assistance, tailored solutions, and on-the-ground expertise. This approach allows us to address regional needs while maintaining the high standards of service and quality expected from a global leader.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </section>

            {/* HOW WE WORK */}
            <section className="How-we-work-section-adhiba ">
                <div className="How-we-work-heading-div">
                    <h3>How we work?</h3>
                    <p>We work by integrating advanced technology, precision manufacturing, and a customer-first approach to deliver innovative, <br />high-quality smart solutions on time and at the right cost.</p>
                </div>
                {/* 4 cards */}
                <div className="container mt-5 mb-3 How-we-work-heading-cards">
                    <div className="row">
                        <div class="card col-lg-3" style={{ backgroundColor: "#393185D9" }}>
                            <div class="card-body">
                                <h5 class="card-title">01</h5>
                                <h6 class="card-subtitle mb-2">Innovative Product Development</h6>
                                <p class="card-text">We design and develop cutting-edge hardware and software solutions tailored to modern needs, blending advanced technology with user-centric innovation for seamless integration into daily life.</p>
                                {/* <div className="hover-icon-adhiba">
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div> */}
                            </div>
                        </div>
                        <div class="card col-lg-3" style={{ backgroundColor: "#393185" }}>
                            <div class="card-body">
                                <h5 class="card-title">02</h5>
                                <h6 class="card-subtitle mb-2">Precision Manufacturing</h6>
                                <p class="card-text">Utilizing state-of-the-art facilities, we ensure every product meets rigorous quality standards, delivering reliable and durable solutions for electronic manufacturing and smart home systems.</p>
                                {/* <div className="hover-icon-adhiba">
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div> */}
                            </div>
                        </div>
                        <div class="card col-lg-3" style={{ backgroundColor: "#3D3D3F" }}>
                            <div class="card-body">
                                <h5 class="card-title">03</h5>
                                <h6 class="card-subtitle mb-2">Efficient Logistics and Delivery</h6>
                                <p class="card-text">With a streamlined logistics network, we prioritize on-time delivery, ensuring our customers receive their products promptly and in perfect condition, every single time.</p>
                                {/* <div className="hover-icon-adhiba">
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div> */}
                            </div>
                        </div>
                        <div class="card col-lg-3" style={{ backgroundColor: "#090909" }}>
                            <div class="card-body">
                                <h5 class="card-title">04</h5>
                                <h6 class="card-subtitle mb-2">Customer-Centric Approach</h6>
                                <p class="card-text">We prioritize customer satisfaction through personalized support, innovative solutions, and exceptional service.</p>
                                {/* <div className="hover-icon-adhiba">
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div> */}
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            {/* 2 IMAGES */}
            <center className=" mission-vission-section">

                <div className="container">

                    <div className="mission-vission-heading-div mb-5">
                        <h3>Our Vision and Mission statements</h3>
                        <p>Our mission is to enhance lives with innovative smart solutions, while our vision is to lead globally<br /> through excellence and innovation.</p>
                    </div>

                    {/* <img src={twoimg1} className="img-fluid w-75" style={{ borderRadius: "0%" }} /> */}

                    <div className="row">
                        <div className="col-lg-6 mission-vission-2card-left-div">
                            <div class="card">
                                <div class="card-body">
                                    <img src={ourvisionimg1} className="img-fluid" />
                                    <h5 class="card-title">Our Mission:</h5>
                                    <p class="card-text">To empower modern living by delivering innovative, high-quality smart home solutions that enhance convenience, improve lifestyles, and exceed customer expectations through precision manufacturing and timely delivery.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mission-vission-2card-right-div">
                            <div class="card">
                                <div class="card-body">
                                    <img src={ourvisionimg1} className="img-fluid" />
                                    <h5 class="card-title">Our Vision:</h5>
                                    <p class="card-text">To be a global leader in smart technology and electronics, revolutionizing everyday life with cutting-edge solutions that combine excellence in quality, affordability, and sustainability, while continuously driving innovation and customer satisfaction.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </center>

            {/* TESTIMONIAL */}
            <section className="Testimonial-about-adhiba">
                <div className="half-color-box">
                    <div className="container spacer por">
                        <div className="Testimonial-heading-div">
                            <h3 className="pt-5">Testimonials</h3>
                            <p>
                                Our testimonials reflect our commitment to excellence, showcasing how our <br />innovative solutions and exceptional service have exceeded customer expectations.
                            </p>
                        </div>
                        <div className="test-arrowbox">
                            <div className="swiper-button-prev-test">
                                <svg fill="#393185" height="34" className="arrow left">
                                    <use xlinkHref="#arrow" />
                                </svg>
                            </div>
                            <div className="swiper-button-next-test">
                                <div className="arrow-right">
                                    <svg fill="#393185" height="60" width="60" className="arrow">
                                        <use xlinkHref="#arrow" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <Swiper
                            className="swiper-container swiper-testimonial"
                            slidesPerView={3}
                            spaceBetween={30}
                            navigation={{
                                nextEl: '.swiper-button-next-test',
                                prevEl: '.swiper-button-prev-test',
                            }}

                        >
                            {/* Slide 1 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=1"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Amanda Jackson</h5>
                                            <p className="user-post">CEO, NRD Group</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum at arcu a arcu
                                        tincidunt condimentum.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 2 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=2"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>John Doe</h5>
                                            <p className="user-post">Designer, ABC Studio</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum at arcu a arcu
                                        tincidunt condimentum.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 3 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=3"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Jane Smith</h5>
                                            <p className="user-post">Manager, XYZ Corp</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 4 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=3"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Jane Smith</h5>
                                            <p className="user-post">Manager, XYZ Corp</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 5 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=3"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Jane Smith</h5>
                                            <p className="user-post">Manager, XYZ Corp</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>

                            {/* Slide 6 */}
                            <SwiperSlide>
                                <div className="review-box">
                                    <div className="media d-flex">
                                        {/* <img
                                            className="mr-3"
                                            src="https://picsum.photos/50/50?random=3"
                                            alt="Generic placeholder"
                                        /> */}
                                        <FontAwesomeIcon icon={faCircleUser} className="testmonial-icon-adhiba" />
                                        <div className="media-body">
                                            <h5>Jane Smith</h5>
                                            <p className="user-post">Manager, XYZ Corp</p>
                                        </div>
                                    </div>
                                    <p className="read">
                                        Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                        veniam.
                                    </p>
                                    {/* <img className="quote" src="img/icons/quote.svg" alt="Quote icon" /> */}
                                </div>
                            </SwiperSlide>
                        </Swiper>

                        {/* <div className="swiper-pagination"></div> */}
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
                        <symbol id="arrow" viewBox="0 0 400.004 400.004">
                            <g>
                                <path d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z" />
                            </g>
                        </symbol>
                    </svg>




                </div>
                <img src={testiimg1} className="img-fluid w-100" style={{ borderRadius: "0" }} />
            </section>

            {/* FOOTER ADHIBA */}
            <Footer />
        </>
    )
}
